p, span {
  font-family: "Inter", sans-serif;
}

.App {
  height: 100vh;
  display: grid;
  grid-template-columns: 5% 95%;
  text-align: center;
}

Dashboard{
  background-color: antiquewhite;
}
