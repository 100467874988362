.insights{
    background-color: #f7f7f7;
    display: grid;
    grid-template-columns: 80% 20%;
    place-items: start;
    padding: 1rem 1rem 0 2rem;
}

header{
    font-family: "Inter", sans-serif;
    font-size: 26px;
    font-weight: 800;
    color: rgba(88, 88, 88, 1);
    text-align: left;
}

.card_row{
    display: flex;
    gap: 20px
}

.filter_selection_wrapper{
    width: 90%;
}
.filter_section{
    width: 100%;
    height: 97%;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    gap:20px;
}

.filter_selection_item{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    place-items: center;
    border-bottom: 1px solid rgba(199, 199, 199, 1);
    & p{
        color: rgba(88, 88, 88, 1);
        text-align: left !important;
        width: 130px;
    }
}

div.insights > div:first-child{
    max-height: 750px;
    overflow-y: scroll;
}

div.insights > div:first-child::-webkit-scrollbar{
    display: none;
}