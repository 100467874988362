.card{
    width: 310px;
    height: 280px;
    min-height: 260px;
    border-radius: 25px;
    display: grid;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.card:hover{
    transform: scale(1.02); /* Slightly enlarge on hover */
    transition: transform 0.5s; /* Smooth transition */
}

.card-title{
    padding: 10px;
    font-size: 22px;
    font-weight: 600;
    text-align: start;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    gap: 10px;
}

.card-body{
    display: grid;
}

.card-body-content{
    display: grid; 
    grid-template-columns: repeat(3, 1fr); 
    text-align: center;
}

.countTitle{
    font-size: 14px;
}

.countValue{
    font-size: 18px; 
    font-weight: 700;
}

.card-footer{
    background: rgba(255, 255, 255, 0.12);
    border-radius: 0px 0px 25px 25px
}

.card-footer-wraper{
    display: grid; 
    grid-template-columns: repeat(3, 1fr); 
    padding: 10px;
}

.card-footer-wraper div{
    display: flex; 
    flex-direction: column; 
    gap: 10px; 
    align-items: center; 
}

.card-footer-title{
    font-size: 12px; 
    font-weight: 400;
}

.card-footer-value{
    font-size: 18px; 
    font-weight: 700;
}

hr{
    width: 130px;
    margin: 1.875em 0;
    border: none;
    height: 1px !important;
    background: repeating-linear-gradient(90deg, rgba(229, 229, 229, 1), rgba(229, 229, 229, 1) 6px, transparent 6px, transparent 12px);
}


