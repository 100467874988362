th,
td {
    width: 10rem;
    height: 60px;
}

table.rounded-corners {
    width: 100%;
    border-spacing: 0;
    border-radius: 15px;
    border: 1px solid rgba(207, 207, 207, 1);
}

table.rounded-corners th:not(:last-child),
table.rounded-corners td:not(:last-child) {
    border-right: 1px solid rgba(207, 207, 207, 1);
}

table.rounded-corners>tbody>tr:not(:last-child)>td,
table.rounded-corners>thead>tr>th {
    border-bottom: 1px solid rgba(207, 207, 207, 1);
}

table.rounded-corners>tbody>tr:nth-child(odd) {
    background-color: rgb(229, 229, 229, 1);
}

table.rounded-corners>thead>tr{
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}

.table-container::-webkit-scrollbar {
    display: none;
}