.action_wrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.tabs{
    display: grid;
    grid-template-columns: 1fr 1.5fr 1.5fr 1.5fr 1.5fr;
    width: 30rem;
    height: 3rem;
    border-bottom: 2px solid rgba(190, 184, 184, 1);
    cursor: pointer;
}

.tabs>span{
    height: 100%;
    width: 100%;
    place-content: center;
}

.downloadFilter_section{
    display: flex;
    width: 20rem;
    justify-content: space-evenly;
    align-items: center;
}

.activeTab{
    color:rgba(73, 26, 203, 1);
    border-bottom: 3px solid rgba(73, 26, 203, 1);
    font-weight: 700;
}

.inactive{
    color: rgba(88,88,88,1);
}

