.sidebar{
    display: grid;
    grid-template-rows: 15% 70% 15%;
    box-shadow: 5px 0px whitesmoke;
}

.logo-container{
    display: grid;
    border-bottom: 1px solid rgb(200 190 190);
}

.selection-container{
    display: flex;
    flex-direction: column;
}

.selection-container div{
    height: 65px;
    display: grid;
    span{
        color: rgba(165, 165, 165, 1);
        font-size: 12px;
        place-self: center;
    }
    img{
        place-self: center;
    }
}

.logout-container{
    display: grid;
    div span{
        color: rgba(165, 165, 165, 1);
        font-size: 12px;
    }
}