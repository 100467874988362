.dashboard{
    background-color: #f7f7f7;
    display: grid;
    grid-template-rows: 10% 10% 80%;
    place-items: start;
    padding: 1rem 3rem 0 3rem;
}

.dashboard  header{
    font-family: "Inter", sans-serif;
    font-size: 26px;
    font-weight: 800;
    color: rgba(88, 88, 88, 1);
}

input[type="checkbox"] {
    position: relative;
    width: 43px;
    height: 21px;
    -webkit-appearance: none;
    appearance: none;
    background: rgba(230, 224, 233, 1);;
    outline: none;
    border-radius: 2rem;
    cursor: pointer;
    border: 2px solid rgba(121, 116, 126, 1);
}
  
input[type="checkbox"]::before {
    content: "";
    margin: 2px 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(121, 116, 126, 1);
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s;
    border: 2px solid rgba(121, 116, 126, 1);
}
  
input[type="checkbox"]:checked::before {
    transform: translateX(140%);
    background: #fff;
}
  
input[type="checkbox"]:checked {
    background: rgba(101, 85, 143, 1);
}

.sub-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

.sub-header-title{
    color: rgba(88,88,88,1);
    font-size: 23px;
    font-weight: 700;
}

.date_filter_wrapper{
    width: 300px;
    background: rgba(73, 26, 203, 0.05);
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: rgba(73, 26, 203, 1);
    font-size: 18px;
    font-weight: 700;
}

.header-action-wrapper{
    width: 20%;
    display: flex;
    justify-content: space-around;
}

.header-action-1, .header-action-2{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 120px;
    height: 45px;
    border-radius: 10px;
    color: rgba(88,88,88,1);
    font-weight: 600;
}

.header-action-2{
    background: rgba(239, 234, 250, 1);
    color: rgba(73, 26, 203, 1) !important;
}

.transition-container {
    animation: fadeIn 0.5s ease, fadeOut 0.5s ease;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  
  .show-one {
    animation: fadeIn 0.5s ease forwards;
  }
  
  .show-all {
    animation: fadeIn 0.5s ease forwards;
  }
  
  .body, .anotherBody {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin-top: 10px;
  }
  
  .other-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }
  
  .anotherBody {
    grid-template-columns: repeat(4, 1fr);
  }



  
 
  
  /* Custom calendar styles */
  .react-datepicker {
    width: 330px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .react-datepicker__aria-live{
    display: none;
  }

  .react-datepicker__input-container input{
    box-shadow: 0px 0px 14px 0px rgba(73, 26, 203, 0.14);
    border: 0.5px solid rgba(73, 26, 203, 1);
    border-radius: 6px;
    height: 30px;
    width: 70%;
    padding-left: 40px;
  }

  .custom-header{
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    place-items: center;
  }

  .react-datepicker__header {
    background-color: rgba(73, 26, 203, 1);
    color: white;
    border-bottom: none;
    padding-top: 10px;
  }
  
  .react-datepicker__current-month {
    font-size: 16px;
    font-weight: bold;
  }

  .react-datepicker__month{
    background-color: whitesmoke;
  }
  
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.5rem;
    height: 2.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0.2rem;
  }
  
  .react-datepicker__day:hover {
    background-color: #f0f0f0;
  }
  
  .react-datepicker__day--selected {
    background-color: rgba(73, 26, 203, 1);
    color: white;
  }
  
  .react-datepicker__day--today {
    font-weight: bold;
    color: #2196f3;
  }
  

  /* Wrapper for the filter icon and popup */
.filter-wrapper {
    position: absolute;
    display: inline-block;
  }
  
  /* Styling for the popup itself */
  .filter-popup {
    position: absolute;
    top: 10rem; /* Adjust to position the popup below the filter button */
    right: 1.8rem;
    width: 230px;
    height: 200px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  /* Date picker container */
  .filter-date-pickers {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 13px;
  }
  
  /* Wrapper for each date picker */
  .datepicker-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .datepicker-wrapper label {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .apply-btn {
    color: rgba(187, 187, 187, 1);
    background-color: white;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    left: 0;
    text-decoration: underline;
    z-index: -1;
  }
  
  .apply-btn:hover {
    color: rgba(73, 26, 203, 1);
  }

  .calendar-1 {
    position: absolute;
    right: 10px;
    top: 13%;
    left: 12%;
    pointer-events: none;
    color: #888; /* Adjust the icon color */
  }
  .calendar-2 {
    position: absolute;
    left: 12%;
    top: 37%;
    pointer-events: none; /* Ensure the icon doesn't block the input interaction */
    color: #888; /* Adjust the icon color */
  }
  