.insight_card {
    width: 260px;
    height: 252px;
    min-height: 210px;
    border-radius: 25px;
    display: grid;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    transition: border-color 0.3s ease;
}

.insight_card:hover {
    border-color: var(--card-border-color);
    box-shadow: var(--card-border-color) 0px 4px 14px;
}

.insight_card-title {
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    text-align: start;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    gap: 10px;
}

.card-body {
    display: grid;
}

.card-body-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
}

.insight_countTitle {
    font-size: 12px;
}

.insight_countValue {
    font-size: 16px;
    font-weight: 700;
}

.card-footer {
    background: rgba(255, 255, 255, 0.12);
    border-radius: 0px 0px 25px 25px
}

.card-footer-wraper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 10px;
}

.card-footer-wraper div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.insight_card-footer-title {
    font-size: 11px;
    font-weight: 400;
}

.insight_card-footer-value {
    font-size: 15px;
    font-weight: 700;
}

hr {
    width: 100px;
    margin: 1.875em 0;
    border: none;
    height: 1px !important;
    background: repeating-linear-gradient(90deg, rgba(229, 229, 229, 1), rgba(229, 229, 229, 1) 6px, transparent 6px, transparent 12px);
}